<div class="inactivity-timer inactivity-timer__container">
    <p id="inactivity-timer__counter" class="hdmui-font-serif">
        {{ (timer$ | async) || 0 | timeFormatter }}
    </p>

    <h2 id="inactivity-timer__title">
        {{ 'DC.INACTIVITY_PAGE.TITLE' | translate }}
    </h2>

    <p id="inactivity-timer__text">
        {{ 'DC.INACTIVITY_PAGE.INFO_TEXT' | translate: { machineId } }}
    </p>

    <button class="inactivity-timer__primary-button hdmui-error" (click)="onPrimaryButtonClick()" mat-flat-button>
        {{ 'DC.INACTIVITY_PAGE.PRIMARY_BUTTON' | translate }}
    </button>

    <button class="inactivity-timer__secondary-button hdmui-error" (click)="onSecondaryButtonClick()" mat-button>
        {{ 'DC.INACTIVITY_PAGE.SECONDARY_BUTTON' | translate }}
    </button>
</div>
