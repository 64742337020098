import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { ROUTE_PARAMS } from '@data-terminal/shared-models';
import { getParamsSnapshot } from '@data-terminal/utils';

import { InactivityTrackerService } from '../services';

@Injectable({
    providedIn: 'root',
})
export class InactivityTimerPageGuard {
    constructor(
        private readonly inactivityTrackerService: InactivityTrackerService,
        private readonly router: Router
    ) {}
    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
        const params = getParamsSnapshot(route);

        const inactiveWorkstationsTimersMap = this.inactivityTrackerService.getInactiveWorkstationsTimersMap();
        const machineId = params[ROUTE_PARAMS.machineId];

        return inactiveWorkstationsTimersMap.has(machineId) || this.router.parseUrl(params[ROUTE_PARAMS.orgId]);
    }
}
