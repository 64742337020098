import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, interval, Observable, switchMap } from 'rxjs';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { ROUTE_PATH } from '@data-terminal/shared-models';

@UntilDestroy()
@Injectable()
export class InactivityPageRedirectionService {
    private canRedirect = true;
    private redirectionsSet: Set<string> = new Set();
    private readonly redirectionsSetSubj$: BehaviorSubject<Set<string>> = new BehaviorSubject<Set<string>>(new Set());
    private readonly redirectionsSet$: Observable<Set<string>> = this.redirectionsSetSubj$.asObservable();

    constructor(
        private readonly ccAuthService: CCAuthService,
        private readonly router: Router
    ) {
        interval(1000)
            .pipe(
                untilDestroyed(this),
                switchMap(() => this.redirectionsSet$.pipe(untilDestroyed(this)))
            )
            .subscribe((redirectionsSet) => {
                this.redirectionsSet = redirectionsSet;

                const [machineId] = redirectionsSet;

                if (machineId && this.canRedirect) {
                    this.redirect(machineId);
                }
            });
    }

    public allowRedirections(): void {
        this.canRedirect = true;
    }

    public addRedirection(machineId: string): void {
        this.redirectionsSet.add(machineId);
        this.redirectionsSetSubj$.next(this.redirectionsSet);
    }

    private redirect(machineId: string): void {
        this.preventRedirections();

        const orgId = this.ccAuthService.getCurrentMembership()?.organizationId || '';
        this.router.navigateByUrl(`${orgId}/${ROUTE_PATH.inactivityTimer}/${machineId}`).then(() => {
            this.redirectionsSet.delete(machineId);
            this.redirectionsSetSubj$.next(this.redirectionsSet);
        });
    }

    private preventRedirections(): void {
        this.canRedirect = false;
    }
}
