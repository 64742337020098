import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

import { Required, TimeFormatterPipe, TimeFormatterPipeModule } from '@data-terminal/utils';
import { HeidelbergLogoComponent } from '@data-terminal/ui-presentational';

import { LegalLineComponent } from '../../../../../../src/app/components/legal-line/legal-line.component';
import { KEY_MACHINE_INACATIVE } from '../../services/inactivity-tracker.service';

@Component({
    standalone: true,
    providers: [TimeFormatterPipe],
    imports: [TranslateModule, MatButtonModule, HdmuiComponentsModule, TimeFormatterPipeModule, AsyncPipe],
    selector: 'data-terminal-inactivity-timer',
    templateUrl: './inactivity-timer.component.html',
    styleUrls: ['./inactivity-timer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InactivityTimerComponent {
    @Input()
    @Required()
    timer$!: Observable<number> | undefined;

    @Input()
    @Required()
    machineId!: string;

    @Output()
    primaryButtonClick = new EventEmitter<void>();

    @Output()
    secondaryButtonClick = new EventEmitter<void>();

    onPrimaryButtonClick(): void {
        localStorage.setItem(`${KEY_MACHINE_INACATIVE}_${this.machineId}`, JSON.stringify(false));
        this.primaryButtonClick.emit();
    }

    onSecondaryButtonClick(): void {
        localStorage.setItem(`${KEY_MACHINE_INACATIVE}_${this.machineId}`, JSON.stringify(false));
        this.secondaryButtonClick.emit();
    }
}

@Component({
    standalone: true,
    imports: [HeidelbergLogoComponent, LegalLineComponent],
    selector: 'data-terminal-inactivity-timer-wrapper',
    template: `
        <div class="hdmui-m-4 inactivity-timer__content">
            <div class="logo__container">
                <data-terminal-heidelberg-logo></data-terminal-heidelberg-logo>
            </div>
            <div class="inactivity-timer__wrapper">
                <ng-content select="data-terminal-inactivity-timer"></ng-content>
            </div>
            <app-legal-line></app-legal-line>
        </div>
    `,
    styles: [
        `
            @use '../../../../../ui-styles/dc-styles' as ui-styles;

            .inactivity-timer__content {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                flex-direction: column;
            }

            .logo__container {
                display: flex;
                justify-content: flex-end;
            }

            .inactivity-timer__wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                margin-bottom: ui-styles.$heidelbergLogoHeight;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InactivityTimerWrapperComponent {}
