import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, delay, interval, map, Observable, Subject, takeWhile, tap } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { RequestMetadata } from '@data-terminal/utils';
import { CaptureMode, Machine, UserMachines } from '@data-terminal/shared-models';
import { GLOBAL_RX_STATE, GlobalState, GlobalStateSelectors } from '@data-terminal/data-access';

import { InactivityPageRedirectionService } from './inactivity-page-redirection.service';

export const KEY_MACHINE_INACATIVE = 'KEY_MACHINE_INACATIVE';

@UntilDestroy()
@Injectable()
export class InactivityTrackerService {
    constructor(
        @Inject(GLOBAL_RX_STATE) private readonly globalState: RxState<GlobalState>,
        private readonly inactivityPageRedirectionService: InactivityPageRedirectionService
    ) {
        this.inactiveWorkstations$
            .pipe(untilDestroyed(this), this.handleActiveWorkstations, this.handleInactiveWorkstations)
            .subscribe();
    }

    private readonly ONE_SECOND = 1000;
    private readonly TIMER_THRESHOLD_MINUTES = 5;

    private readonly inactiveWorkstationsTimersMap: Map<string, Observable<number>> = new Map();
    private readonly inactiveWorkstationsOffsetMap: Map<string, number> = new Map();

    private readonly userMachines$: Observable<RequestMetadata<UserMachines>> = this.globalState.select(
        GlobalStateSelectors.USER_MACHINES
    );

    private readonly mapToInactiveWorkstations = (
        stream$: Observable<RequestMetadata<UserMachines>>
    ): Observable<Machine[]> =>
        stream$.pipe(
            map((requestMetadata) => {
                const data = requestMetadata.data;
                if (!data || !data?.assignedMachines || data?.assignedMachines.length === 0) {
                    return [];
                }
                return data?.assignedMachines.filter((machine) => {
                    return (
                        machine.runningOperation?.opName === undefined && machine.captureMode === CaptureMode.START_STOP
                    );
                });
            })
        );

    private readonly inactiveWorkstations$: Observable<Machine[]> = this.userMachines$.pipe(
        this.mapToInactiveWorkstations
    );

    private readonly handleActiveWorkstations = (stream$: Observable<Machine[]>): Observable<Machine[]> =>
        stream$.pipe(
            tap((inactiveWorkstations) => {
                [...this.inactiveWorkstationsTimersMap.keys()].forEach((machineId) => {
                    if (!inactiveWorkstations.find((machine) => machine.machineId === machineId)) {
                        this.inactiveWorkstationsTimersMap.delete(machineId);
                        this.inactivityPageRedirectionService.allowRedirections();
                    }
                });
            })
        );

    private readonly handleInactiveWorkstations = (stream$: Observable<Machine[]>): Observable<Machine[]> =>
        stream$.pipe(
            tap((inactiveWorkstations) => {
                inactiveWorkstations.forEach((machine) => {
                    if (!this.inactiveWorkstationsTimersMap.has(machine.machineId)) {
                        localStorage.setItem(`${KEY_MACHINE_INACATIVE}_${machine.machineId}`, JSON.stringify(false));
                        const { machineId, lastActiveDate, signedOnUsers } = machine;
                        const captain = signedOnUsers.find((user) => user.captain);
                        const captainLastActiveTimestamp = Math.max(
                            captain?.timestamp || 0,
                            captain?.inactiveSince || 0
                        );
                        const lastActiveTimestamp = Math.max(
                            lastActiveDate === undefined || lastActiveDate === null || lastActiveDate === 0
                                ? Date.now()
                                : lastActiveDate,
                            captainLastActiveTimestamp
                        );

                        const currentTime = Date.now();
                        const secondsSinceInactive = currentTime - lastActiveTimestamp;
                        const inactivityTimerSubj$: Subject<number> = new BehaviorSubject(secondsSinceInactive);
                        const inactivityTimer$: Observable<number> = inactivityTimerSubj$.asObservable();
                        this.inactiveWorkstationsTimersMap.set(machineId, inactivityTimer$);
                        this.inactiveWorkstationsOffsetMap.set(machineId, 0);
                        this.inactivityPageRedirectionService.allowRedirections();
                        this.startTimer(machineId, inactivityTimer$, inactivityTimerSubj$, lastActiveTimestamp);
                        this.startOffset(machineId, lastActiveTimestamp);
                    } else {
                        this.inactivityPageRedirectionService.allowRedirections();
                    }
                    // Weitere Aktionen für inaktive Workstations durchführen
                });
            })
        );

    // private readonly handleInactiveWorkstations = (stream$: Observable<Machine[]>): Observable<Machine[]> =>
    // stream$.pipe(
    //     tap((inactiveWorkstations) => {
    //         inactiveWorkstations.forEach((machine) => {
    //             const { machineId } = machine;

    //                 this.startTimer(machineId, inactivityTimer$, inactivityTimerSubj$);
    //             }
    //         });
    //     })
    // );

    public getInactiveWorkstations$(): Observable<Machine[]> {
        return this.inactiveWorkstations$;
    }

    public getInactiveWorkstationsTimersMap(): Map<string, Observable<number>> {
        return this.inactiveWorkstationsTimersMap;
    }

    private startTimer(
        machineId: string,
        inactivityTimer$: Observable<number>,
        inactivityTimerSubj$: Subject<number>,
        inactiveSince: number
    ): void {
        inactivityTimer$
            .pipe(
                takeWhile(() => this.inactiveWorkstationsTimersMap.has(machineId)),
                untilDestroyed(this),
                delay(1000)
            )
            .subscribe(() => {
                const newTime = Date.now() - inactiveSince;
                inactivityTimerSubj$.next(newTime);
                // Add redirection if the timer is equal or greater that
                const checkedReturn = localStorage.getItem(`${KEY_MACHINE_INACATIVE}_${machineId}`);
                const checked = checkedReturn ? JSON.parse(checkedReturn) : false;
                const timerOffset = this.inactiveWorkstationsOffsetMap.get(machineId) || 0;
                if (newTime >= this.TIMER_THRESHOLD_MINUTES * 60 * 1000 + timerOffset && !checked) {
                    localStorage.setItem(`${KEY_MACHINE_INACATIVE}_${machineId}`, JSON.stringify(true));
                    this.inactivityPageRedirectionService.addRedirection(machineId);
                }
            });
    }

    private startOffset(machineId: string, inactiveSince: number): void {
        interval(1000)
            .pipe(
                takeWhile(() => this.inactiveWorkstationsTimersMap.has(machineId)),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const checkedReturn = localStorage.getItem(`${KEY_MACHINE_INACATIVE}_${machineId}`);
                const checked = checkedReturn ? JSON.parse(checkedReturn) : false;
                if (checked) {
                    this.inactiveWorkstationsOffsetMap.set(machineId, Date.now() - inactiveSince);
                }
            });
    }
}
